var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",staticStyle:{"margin-top":"7.5vh","padding-left":"3vw","padding-right":"3vw"}},[_c('b-row',[_c('h1',{staticClass:"text-primary mb-3 ml-4"},[_vm._v(_vm._s(_vm.Title))])]),_c('b-card',{staticClass:"card-box-shadow"},[_c('b-row',[_c('b-col',{attrs:{"md":_vm.isUserHasSuppliers ? 6 : false,"sm":_vm.isUserHasSuppliers ? 12 : false}},[_c('b-form-group',{attrs:{"label":"Delivery Site","label-for":"ddlDeliverySite"}},[_c('multiselect',{class:!_vm.inputValidations.chkState('selectedConsignee', _vm.$v)
                  ? 'is-invalid'
                  : '',attrs:{"id":"msConsignee","label":"name","track-by":"id","placeholder":"Type to search available Consignees","open-direction":"bottom","options":_vm.consignees,"multiple":true,"loading":_vm.isSearching,"clear-on-select":false,"close-on-select":false,"limit":3,"max-height":600,"show-no-results":true,"hide-selected":false},on:{"search-change":_vm.search},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-sm"},[_vm._v(_vm._s(props.option.name)+" ")]),_c('div',{staticClass:"col-sm"},[_vm._v(_vm._s(props.option.billTo)+" ")])]),_c('br'),_c('span',{staticClass:"city-state-text"},[_vm._v(_vm._s(props.option.address)+" "+_vm._s(props.option.city)+", "+_vm._s(props.option.state))])])]}},{key:"clear",fn:function(props){return [(_vm.selectedConsignee)?_c('div',{staticClass:"multiselect__clear",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clearSelected()}}}):_vm._e()]}}]),model:{value:(_vm.selectedConsignee),callback:function ($$v) {_vm.selectedConsignee=$$v},expression:"selectedConsignee"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("No Consignees found. Consider changing the search query.")])]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.inputValidations.chkState('selectedConsignee', _vm.$v)}},[_vm._v("Delivery site is required.")])],1)],1),(_vm.isUserHasSuppliers)?_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.supplierDropdownLabel.label,"label-for":"msSuppliers"}},[_c('multiselect',{class:!_vm.inputValidations.chkState('selectedSuppliers', _vm.$v)
                  ? 'is-invalid'
                  : '',attrs:{"id":"msSuppliers","label":"name","track-by":"id","placeholder":_vm.supplierDropdownLabel.placeholder,"open-direction":"bottom","options":_vm.loadedSuppliers,"multiple":true,"clear-on-select":false,"close-on-select":false,"limit":3,"show-no-results":false},on:{"search-change":_vm.searchSupplierMonitor},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_vm._v(" "+_vm._s(props.option.name)+" "),_c('br'),_c('span',{staticClass:"city-state-text"},[_vm._v(_vm._s(props.option.address)+" "+_vm._s(props.option.city)+", "+_vm._s(props.option.state))])])]}},{key:"clear",fn:function(props){return [(_vm.selectedSuppliers.length)?_c('div',{staticClass:"multiselect__clear",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();_vm.selectedSuppliers = [];}}}):_vm._e()]}}],null,false,3175549824),model:{value:(_vm.selectedSuppliers),callback:function ($$v) {_vm.selectedSuppliers=$$v},expression:"selectedSuppliers"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("No Suppliers found. Consider changing the search query.")])]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.inputValidations.chkState('selectedSuppliers', _vm.$v)}},[_vm._v("Supplier site is required.")])],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"txtSendTo"}},[_vm._v(" Send To "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(
                  'You may enter multiple email addresses separated by a comma. We recommend using a distribution list for multiple email addresses.'
                ),expression:"\n                  'You may enter multiple email addresses separated by a comma. We recommend using a distribution list for multiple email addresses.'\n                ",modifiers:{"hover":true}}],staticClass:"fa fa-question-circle text-primary bottom-align-text",attrs:{"title":"Send To"}})]),_c('b-form-textarea',{class:_vm.inputValidations.checkValidEmails(this.$v.sendTo.$model)
                  ? ''
                  : 'is-invalid',staticStyle:{"white-space":"nowrap","overflow-y":"hidden"},attrs:{"id":"txtSendTo","rows":"1","trim":"","no-resize":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();}},model:{value:(_vm.sendTo),callback:function ($$v) {_vm.sendTo=$$v},expression:"sendTo"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.inputValidations.checkValidEmails(this.$v.sendTo.$model)}},[_vm._v("Incorrect text structure for emails, please use the correct delimiters (',').")])],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Notification Events","label-for":"ddlEventTypes"}},[_c('treeselect',{class:_vm.inputValidations.chkState('eventTypes', _vm.$v) == false
                  ? 'is-invalid'
                  : '',attrs:{"instanceId":"ddlEventTypes","multiple":true,"options":_vm.eventTypeOptions,"valueFormat":"object"},model:{value:(_vm.eventTypes),callback:function ($$v) {_vm.eventTypes=$$v},expression:"eventTypes"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.inputValidations.chkState('eventTypes', _vm.$v)}},[_vm._v("Minimum of 1 event type is required.")])],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveNotification()}}},[_vm._v("Save")]),_c('b-button',{staticClass:"ml-3",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.closeWindow()}}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }