<template>
	<div class="container vld-parent">
		<loading
			:active.sync="isLoading"
			:is-full-page="true"
			color="#114C8E"
		></loading>
		<b-row>
			<h1 class="text-primary">Notifications</h1>
		</b-row>
		<b-row>
			<b-card style="width: 100%">
				<b-row>
					<b-col>
						<b-button
							variant="primary"
							@click="showNotificationSlideIn('Add Notification', null)"
						>
							<i class="fa fa-plus mr-1"></i>Add New Notification
						</b-button>
					</b-col>
				</b-row>

				<b-row align-h="between" class="my-3">
					<b-col md="6" class="my-1">
						<b-form inline>
							<label class="mr-sm-2 col-form-label-sm" for="filterInput"
								>Search: </label
							>
							<b-input-group size="sm">
								<b-form-input
									v-model.lazy="filter"
									type="search"
									id="filterInput"
									placeholder="Type to Search"
								></b-form-input>
								<b-input-group-append>
									<b-button :disabled="!filter" @click="filter = ''"
										>Clear</b-button
									>
								</b-input-group-append>
							</b-input-group>
						</b-form>
					</b-col>

					<b-col md="6" class="my-1">
						<b-form inline class="float-md-right">
							<label class="mr-sm-2 col-form-label-sm" for="perPageSelect"
								>Per page:</label
							>
							<b-form-select
								v-model="perPage"
								id="perPageSelect"
								size="sm"
								:options="pageOptions"
							></b-form-select>
						</b-form>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-table
							:items="notifications"
							:fields="fields"
							responsive="sm"
							outlined
							striped
							:current-page="currentPage"
							:per-page="perPage"
							:filter-ignored-fields="ignoreFields"
							:filter="filter"
							@filtered="onFiltered"
							:sort-direction="sortDirection"
						>
							<template v-slot:cell(consignee)="data">
								<span v-for="consignee in data.value" :key="consignee.kagCompanyId">
									{{ consignee.kagCompanyId }}
								</span>
							</template>

						<template v-slot:cell(suppliers)="data">
							<span :id="`popover-target-supplier-${data.item.id}`">
								<div v-html="getDefaultText(data.item.suppliers)" />
							</span>

							<b-popover
								:target="`popover-target-supplier-${data.item.id}`"
								triggers="hover"
								placement="lefttop">
								<template v-slot:title>Suppliers</template>
									<div v-html="getPopOverText(data.item.suppliers)" />
							</b-popover>
						</template>

						<template v-slot:cell(events)="data">
							<span :id="`popover-target-${data.item.id}`">
								<div v-html="getDefaultText(data.item.eventTypes)" />
							</span>

							<b-popover
								:target="`popover-target-${data.item.id}`"
								triggers="hover"
								placement="lefttop"
							>
								<template v-slot:title>Configured Events</template>
								<div v-html="getPopOverText(data.item.eventTypes)" />
							</b-popover>
						</template>

							<template v-slot:cell(actions)="data">
								<b-button
									size="sm"
									variant="primary"
									class="mb-1 mr-2"
									id="btnEditUser"
									@click="showNotificationSlideIn('Edit', data.item)"
								>
									<i class="fa fa-edit mr-1"></i>
									Edit
								</b-button>

								<b-button
									size="sm"
									variant="danger"
									class="mb-1 btn-sm"
									style="cursor:pointer"
									@click="confirmDeleteNotification(data.item)"
								>
									<i class="fa fa-trash mr-1"></i>
									Delete
								</b-button>
							</template>
						</b-table>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							last-number
							class="my-2"
						></b-pagination>
						Total Records : {{ totalRows }}
					</b-col>
				</b-row>
			</b-card>
		</b-row>

		<slideout-panel ref="slideoutPanelRef" data-testid="slideOutPanel"></slideout-panel>

	</div>
</template>

<script>
import { mapActions } from "vuex";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AddEditNotificationSlideIn from "@/components/notifications/AddEditNotificationSlideIn.vue";
import { KagInternalCustomerId } from "@/shared/utils/permissions";
import { mapGetters } from "vuex";

export default {
	name: "Notifications.vue",

	components: {
		Loading
	},

	data() {
		return {
			isLoading: false,
			showModal: false,
			modalTitle: null,
			perPage: 10,
			totalRows: 0,
			currentPage: 1,
			currentPagePag: 1,
			pageOptions: [5, 10, 25, 50, 100, 500],
			filter: null,
			sortDirection: "asc",
			fields: [
				{ key: "consignee", label: "Consignee", sortable: true },
				{ key: "suppliers", label: "Suppliers", sortable: false },
				{ key: "sendTo", label: "Send To", sortable: true },
				{ key: "events", label: "Events", sortable: false },
				{ key: "actions", label: "Actions" }
			],
			ignoreFields: ["id"],
		};
	},

	methods: {
		...mapActions("notification", ["setConsignees"]),

		showNotificationSlideIn(title, customer) {
			const panel = this.$showPanel({
				component: AddEditNotificationSlideIn,
				props: { Notification: customer, Title: title },
				openOn: 'right'
			});

			//Due to the dynamic implementation of the panel. This is the workaround to update the notifications when created.
			panel.promise.then(async (result) => {
				if(result === true){
					this.isLoading = true;
					// Introduce a delay of 5 seconds (adjust as needed)
					await new Promise((resolve) => {
						setTimeout(resolve, 5000); // 5000 milliseconds = 5 seconds
					});
					await this.getNotificationList();
					this.isLoading = false;
				}
			});
    },

		confirmDeleteNotification(id) {
			this.$snotify.error(
				"Are you sure you want to delete this record",
				"Delete",
				{
					backdrop: 0.3,
					timeout: 0,
					closeOnClick: false,
					position: "centerCenter",
					buttons: [
						{
							text: "Ok",
							action: async toast => {
								await this.deleteNotification(id);
								this.$snotify.remove(toast.id);
							},
							bold: true
						},
						{ text: "Cancel", action: null, bold: false }
					]
				}
			);
		},

		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			// this.totalRows = this.totalNotifications;
			this.currentPage = 1;
		},

		async deleteNotification(item) {
			this.isLoading = true;

			var requestData = {
				jwtToken: this.$store.state.user.jwtToken,
				id: item.id,
				subscriptionRelationId: item.consignee[0].id
			};

			await this.$store.dispatch(
				"notification/deleteNotification",
				requestData
			);

			//The -1 is needed to aid on the pagination handle. If currentPagePag is 1 then the skip must be 0 and so on.
			await this.getNotificationList();

			this.isLoading = false;
		},

		getDefaultText(eventTypes) {

			if(!eventTypes) { return ""; }
			let text = "";
			let count = eventTypes.length;

			if (count > 2) {
				count = 2;
			}

			for (let i = 0; i < count; i++) {
				text = text + `<span>${eventTypes[i].name}</span><br />`;
			}

			if (eventTypes.length > 2) {
				return text + ".....";
			}

			return text;
		},

		getPopOverText(eventTypes) {

			if(!eventTypes) { return ""; }
			let text = "";

			eventTypes.forEach(e => {
				text = text + `<span>${e.name}</span><br />`;
			});

			return text;
		},

		async getNotificationList(skip = 0, take = 2000){
			try {
				var requestData = {
					jwtToken: this.$store.state.user.jwtToken,
					skip: skip,
					take: take
				};

				await this.$store.dispatch(
					"notification/getAllNotifications",
					requestData
				);

				await this.$store.dispatch(
					"notification/getAllNotificationEventTypes",
					requestData
				);

				this.totalRows = this.totalNotifications;
			} catch (error) {
				var errorMessage = handleRequestError(error);
				this.$snotify.error(errorMessage, "Error");
			}
		}
	},

	async created() {
		this.isLoading = true;
		//handles modal closing from child component
		this.$root.$on("toggleModalState", val => {
			this.showModal = val;
		});

		
		//Adjust so SSO user gets all the notifications on their first page.
		if(this.loggedInUser.decodedToken.customer_id === this.KagInternalCustomerId)this.perPage = 500;

		await this.getNotificationList();

		this.isLoading = false;
	},

	computed: {
		...mapGetters("user", ["loggedInUser"]),

		notifications() {
			let notif = this.$store.state.notification.notifications;
			let notifParsed = [];

			if(notif){
				notif.forEach( element => {
	
					element.consignee.forEach(consignee => {
						let temp = {
							consignee: [consignee],
							eventTypes: element.eventTypes,
							id: element.id,
							sendTo: element.sendTo,
							suppliers: element.suppliers
						}
						notifParsed.push(temp);
					});
				});
			}

			return notifParsed;
		},

		totalNotifications(){
			return this.$store.state.notification.totalNotifications;
		},
		
		KagInternalCustomerId() {
      return KagInternalCustomerId;
    },
	}
};
</script>

<style scoped>
.is-invalid {
	border: solid #c22032 1px;
	border-radius: 5px;
}

.permission-badge {
	margin-left: 5px;
	font-size: 0.85em;
	font-weight: 700;
}

.company-name {
	font-size: 14px;
	font-weight: 700;
}
</style>
