<template>
  <div
    class="container"
    style="margin-top: 7.5vh; padding-left:3vw; padding-right:3vw"
  >
    <b-row>
      <h1 class="text-primary mb-3 ml-4">{{ Title }}</h1>
    </b-row>
    <b-card class="card-box-shadow">
      <b-row>
        <b-col
          :md="isUserHasSuppliers ? 6 : false"
          :sm="isUserHasSuppliers ? 12 : false">
          <b-form-group label="Delivery Site" label-for="ddlDeliverySite">
            <multiselect
              id="msConsignee"
              label="name"
              track-by="id"
              placeholder="Type to search available Consignees"
              open-direction="bottom"
              :options="consignees"
              v-model="selectedConsignee"
              @search-change="search"
              :multiple="true"
              :loading="isSearching"
              :clear-on-select="false"
              :close-on-select="false"
              :limit="3"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="false"
              :class="
                !inputValidations.chkState('selectedConsignee', $v)
                  ? 'is-invalid'
                  : ''
              "
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
									<div class="row mb-2">
										<div class="col-sm">{{ props.option.name }} </div>
										<div class="col-sm">{{ props.option.billTo }} </div>		
									</div>
                  <br />
                  <span class="city-state-text"
                    >{{ props.option.address }} {{ props.option.city }},
                    {{ props.option.state }}</span
                  >
                </div>
              </template>

              <template slot="clear" slot-scope="props">
                <div
                  class="multiselect__clear"
                  v-if="selectedConsignee"
                  @mousedown.prevent.stop="clearSelected()"
                ></div>
              </template>
              <span slot="noResult"
                >No Consignees found. Consider changing the search query.</span
              >
            </multiselect>

            <b-form-invalid-feedback
              :state="inputValidations.chkState('selectedConsignee', $v)"
              >Delivery site is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>

        <b-col v-if="isUserHasSuppliers" md="6" sm="12">
          <b-form-group :label="supplierDropdownLabel.label" label-for="msSuppliers">
            <multiselect
              id="msSuppliers"
              label="name"
              track-by="id"
              :placeholder="supplierDropdownLabel.placeholder"
              v-model="selectedSuppliers"
              open-direction="bottom"
              :options="loadedSuppliers"
              :multiple="true"
              :clear-on-select="false"
              :close-on-select="false"
              :limit="3"
              :show-no-results="false"
              @search-change="searchSupplierMonitor"
              :class="
                !inputValidations.chkState('selectedSuppliers', $v)
                  ? 'is-invalid'
                  : ''
              "
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  {{ props.option.name }}
									<br />
                  <span class="city-state-text"
                    >{{ props.option.address }} {{ props.option.city }},
                    {{ props.option.state }}</span
                  >
                </div>
              </template>

              <template slot="clear" slot-scope="props">
                <div
                  class="multiselect__clear"
                  v-if="selectedSuppliers.length"
                  @mousedown.prevent.stop="selectedSuppliers = [];"
                />
              </template>
              <span slot="noResult">No Suppliers found. Consider changing the search query.</span>
            </multiselect>

            <b-form-invalid-feedback
              :state="inputValidations.chkState('selectedSuppliers', $v)"
              >Supplier site is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" md="12" sm="12">
          <b-form-group>
            <label for="txtSendTo">
              Send To
              <i
                class="fa fa-question-circle text-primary bottom-align-text"
                v-b-popover.hover="
                  'You may enter multiple email addresses separated by a comma. We recommend using a distribution list for multiple email addresses.'
                "
                title="Send To"
              ></i>
            </label>

            <b-form-textarea
              id="txtSendTo"
              v-model="sendTo"
              rows="1"
              style="white-space: nowrap; overflow-y: hidden;"
              trim
              :class="
                inputValidations.checkValidEmails(this.$v.sendTo.$model)
                  ? ''
                  : 'is-invalid'
              "
              no-resize
              @keydown.enter.exact.prevent
            ></b-form-textarea>

            <b-form-invalid-feedback
              :state="inputValidations.checkValidEmails(this.$v.sendTo.$model)"
              >Incorrect text structure for emails, please use the correct delimiters (',').</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Notification Events" label-for="ddlEventTypes">
            <treeselect
              instanceId="ddlEventTypes"
              v-model="eventTypes"
              :multiple="true"
              :options="eventTypeOptions"
              valueFormat="object"
              :class="
                inputValidations.chkState('eventTypes', $v) == false
                  ? 'is-invalid'
                  : ''
              "
            ></treeselect>

            <b-form-invalid-feedback
              :state="inputValidations.chkState('eventTypes', $v)"
              >Minimum of 1 event type is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>


      </b-row>
      <b-row>
        <b-col>
          <b-button variant="primary" @click="saveNotification()"
            >Save</b-button
          >
          <b-button variant="outline-danger" class="ml-3" @click="closeWindow()"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import inputValidations from "@/shared/utils/input-validation";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "@/services/RepositoryFactory";
import mixin from "@/shared/mixin";
import Treeselect from "@riophae/vue-treeselect";
import { divisions } from "@/shared/constants.js"
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const BillTosRepository = RepositoryFactory.get("companiesRepository");

export default {
  name: "AddEditNotificationSlideIn.vue",
	
  props: ["Notification", "Title"],

  components: {
    Multiselect,
    Treeselect
  },

  data() {
    return {
      sendTo: null,
      eventTypes: [],
      inputValidations: inputValidations,
      consignees: [],
      selectedConsignee: [],
      selectedSuppliers: [],
      loadedSuppliers: [],
      isUserHasSuppliers: false,
      isSearching: false,
      isDirty: true,
    };
  },

  async created() {
	let searchTermSuppliers = [''];
    if (this.Notification) {

	  if(this.Notification.suppliers && this.Notification.suppliers.length)
      {
        searchTermSuppliers.push(...this.Notification.suppliers.flatMap(s => s.id));
      }
      this.Notification.consignee.push('')
      Promise.all([
        await this.load(this.Notification.consignee),
        await this.loadSupplier(searchTermSuppliers)
      ])
        .catch((error) => {
          let errorMessage = handleRequestError(error);
          this.$snotify.error(errorMessage, "Error");
        }).finally(()=>{
          this.isSearching = false;
        });

      this.sendTo = this.Notification.sendTo;

      let toUpdateEventTypes = this.Notification.eventTypes.map((billTo) => ({
        id: billTo.id,
        label: billTo.name
      }));
      //Came with the real Id, need to be parsed by label against to the options Id
      this.eventTypes = _.intersectionBy(this.eventTypeOptions, toUpdateEventTypes, 'label');
    } else {

      Promise.all([
        await this.load(['']),
        await this.loadSupplier(searchTermSuppliers)
      ]).catch((error)=>{
          let errorMessage = handleRequestError(error);
          this.$snotify.error(errorMessage, "Error");
        }).finally(()=>{
          this.isSearching = false;
        });
    }

  },

  methods: {
    closeWindow() {
      this.$v.$reset();
      this.$emit("closePanel", false);
    },

    async saveNotification() {

        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (this.Notification) {

            let notification = {
              id: this.Notification.id,
              consignee: this.selectedConsignee.map(a => ({ KagCompanyId: a.id, Division: a.division })),
              sendTo: this.sendTo,
              eventTypes: this.eventTypes.map((et) => ({  id: et.id, name: et.label })),
              suppliers: this.selectedSuppliers.map(a => ({id: a.id, name: a.name}))
            };

            await this.$store.dispatch( "notification/updateNotification", { data: notification } )
              .catch((error) => {
                let errorMessage = handleRequestError(error);
                this.$snotify.error(errorMessage, "Error");
              });

          } else {
            let mappedConsingee = this.selectedConsignee.map(a => ({ KagCompanyId: a.id, Division: a.division }));
            mappedConsingee.forEach(currentConsingee => {
              let notification = {
                sendTo: this.sendTo,
                consignee: [currentConsingee],
                eventTypes: this.eventTypes.map(et => ({ id: et.id, name: et.label })),
                suppliers: this.selectedSuppliers.map(a => ({ id: a.id, name: a.name }))
              };
            
            
            this.$store.dispatch("notification/addNewNotification", { data: notification } )
              .catch((error) => {
                let errorMessage = handleRequestError(error);
                this.$snotify.error(errorMessage, "Error");
              });
            });
          }

					//True or false reset the notifications in case a new one is created so it's added on the total and filled in the list
          this.$emit("closePanel", this.Notification === null ? true : false);
          this.$v.$reset();
          this.sendTo = null;
          this.eventTypes = [];
        }

    },

    search: _.debounce(async function(searchTerm) {
      this.isDirty = true;
      await this.searchConsignee(searchTerm);
    }, 350),

    async searchConsignee(searchTerm){
      try {
        const response = await BillTosRepository.getConsignees(searchTerm)

        if(response.data){

          const newItems = response.data
          .filter(responseConsignee => !this.consignees
              .find(consignee => consignee.id === responseConsignee.id))

          this.consignees = [...this.consignees, ...newItems]
        }

      }catch (error){
        let errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    searchSupplierMonitor: _.debounce(async function(searchTerm) {
      await this.searchSupplier(searchTerm);
    }, 350),

    async searchSupplier(searchTerm){

      try {

        const response = await BillTosRepository.getSuppliers({
          searchTerm: searchTerm,
          consignee: this.selectedConsignee,
          divisionToFilter: divisions.FDG
        });

        let newItems =_.uniqWith(response.data,
          (a, b) => a.id === b.id || a.name === b.name);

        if(newItems.length)
          newItems = newItems
            .filter(responseSupplier => !this.loadedSuppliers
              .find(supplier => supplier.id === responseSupplier.id))

          this.loadedSuppliers = [...this.loadedSuppliers, ...newItems];

      }catch (error){
        let errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    /**
     *
     * @param {Array.<String ||  {kagCompanyId:String, division: String}>} query
     * @returns {Promise<void>}
     */
    async load(query) {

      if (!this.isDirty) { return; }

      this.isSearching = true;

      const responses = await Promise.all(
        query.map(toSelectConsignee => {
          const currentConsigneeId = toSelectConsignee.kagCompanyId || toSelectConsignee;
          return BillTosRepository.getConsignees(currentConsigneeId);
        })
      );

      const flatResponseItems = responses.flatMap(response => response.data)
      let newItems = _.uniqWith(flatResponseItems,
        (a, b) => a.id === b.id || a.name === b.name);

      if (newItems.length) {

        _.remove(newItems, (item) => {
          for (var e in this.consignees) {
            return item.id === e.id
          }
        })

        this.consignees = [...this.consignees, ...newItems]

        for (const toSelectConsignee of query) {
          if (!toSelectConsignee) { continue }

          this.isDirty = true;
          const selectedConsigneeId = toSelectConsignee.kagCompanyId || toSelectConsignee;

          this.selectedConsignee.push(
            ...this.consignees.filter(consignee => consignee.id === selectedConsigneeId)
          );

        }
      }
    },

		async loadSupplier(searchArrayQuery) {
			if (!this.consignees) {
				return;
			}

			const firstConsigneeDivision = this.consignees[0].division;
			this.isSearchingSuppliers = true;

			let repositoryFunction = null;
			let responses = [];

			if (firstConsigneeDivision.toUpperCase() === 'FDG') {
				repositoryFunction = BillTosRepository.getSuppliers;
				responses = await Promise.all(
					searchArrayQuery.map(searchQuery => {
						const requestData = {
							searchTerm: searchQuery,
							divisionToFilter: firstConsigneeDivision.toUpperCase()
						};

						return repositoryFunction(requestData);
					})
				);
			}

			let newDirtyItems = responses.flatMap(response => response.data);
			let newItems = _.uniqWith(newDirtyItems, (a, b) => a.id === b.id || a.name === b.name);

			if (newItems.length) {
				newItems = newItems.filter(responseSupplier => !this.loadedSuppliers.find(supplier => supplier.id === responseSupplier.id));

				this.loadedSuppliers.push(...newItems);

				for (const toSelectSuppliers of searchArrayQuery) {
					this.selectedSuppliers.push(
						...this.loadedSuppliers.filter(supplier => supplier.id === toSelectSuppliers)
					);
				}
			}

			this.isUserHasSuppliers = this.loadedSuppliers.length > 0;
			this.isSearchingSuppliers = false;
		},

    clearSelected() {
      this.selectedConsignee = [];
    },
  },

  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),

    ...mapGetters("user", ["loggedInUser"]),

    eventTypeOptions() {
      var eventTypes = this.$store.state.notification.notificationEventTypes;

      var eventTypesOptions =
      eventTypes.map((eventType) => ({
          id: eventType.eventId,
          label: eventType.name
      }));
      return eventTypesOptions;
    },

    loggedInCustomerId() {
      return this.loggedInUser.customerId;
    },

    loggedInUserId() {
      return this.oidcUser.sub;
    },

    isNew() {
      return this.Notification == null ? true : false;
    },

		supplierDropdownLabel(){
			let labelString = 'Suppliers';
			let labelPlaceHolder = 'Type to search available Suppliers';
			
			if (!this.consignees) {
				return;
			}

			if(this.consignees[0].division === 'SPG'){
				labelString = 'Shippers';
				labelPlaceHolder = 'Type to search available Shippers'
			}
			return {
				label: labelString,
				placeholder: labelPlaceHolder
			};
		}
  },

  validations() {
    return {
      sendTo: {
        required
      },

      selectedConsignee: {
  		required: requiredIf(() =>{
          return !this.selectedSuppliers.length
        })
      },

      selectedSuppliers:{
        required: requiredIf(() =>{
          return !this.selectedConsignee.length
        })
      },

      eventTypes: {
        required
      }
    };
  },

  mixins: [mixin]
};
</script>

<style scoped>
.vue-treeselect__multi-value-item {
  background: #114c8e !important;
  color: white !important;
  font-weight: 700;
}

.vue-treeselect__value-remove {
  color: white;
}

.v-select {
  background-color: #fff;
}

.card-box-shadow {
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
  background-color: #f7f7f7;
}
</style>
